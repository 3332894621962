import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

interface LinkProps {
  href: string
  text: string | JSX.Element
}

const Link = ({ href, text }: LinkProps) => {
  const [underline, setUnderline] = useState<string>('none')
  const [color, setColor] = useState<string>('#29B3F1')

  const hover = () => {
    setUnderline('underline')
    setColor('white')
  }
  const leave = () => {
    setUnderline('none')
    setColor('#29B3F1')
  }

  return <a href={href} style={{ color: color, textDecoration: underline, transition: 'color 0.5s', margin: '1em' }} onMouseEnter={hover} onMouseLeave={leave}>{text}</a>
}

const App = () => {
  const randomDate = require('random-datetime')
  const [releaseDate, setReleaseDate] = useState<Date>(new Date())

  const changeDate = () => setReleaseDate(randomDate({ hour: 0, minute: 0, second: 0, millisecond: 0, year: Math.floor(Math.random() * 3 + 2022) }))

  useEffect(() => {
    setInterval(() => changeDate(), 3000)
  })
  
  return (
    <div style={{
      backgroundColor: '#040B30',
      color: 'white',
      minHeight: '100vh',
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap-reverse'
    }}>
      <div className="content-half" style={{ height: '100%' }}>
        <img src="/pregame.JPEG" style={{ minWidth: 150, width: '30%', height: 'auto', margin: '2%' }} />
        <div style={{ maxWidth: 900, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '0.5% 1%' }}>
          <p>
            Any content containing the above marking is officially rated "PG" for
            "PREGAME", in accordance with DJ Powda P and his affiliates' official
            rating system. Content with this rating is included within the
            "Pregame Content Campaign", a collection of various media to be
            released prior to the events taking place at "Zae's Crib" on
            <br />
            <br />
            <span style={{ color: '#29B3F1' }}>{releaseDate.toLocaleDateString("en-US", { dateStyle: 'long' })}</span>
            <br />
            <br />
            While the events at "Zae's Crib" are open to the
            public, some activites are age-restricted. Several special guests will
            be in attendance these events, but their identities and their purpose
            at these events will not be disclosed to the general public at this
            time. Any further inquiries regarding the meaning of this rating or
            further details pertaining to the events at "Zae's Crib" can be
            answered at any of the sources below.
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
            <Link href="https://instagram.com/djpowdap" text="DJ Powda P - Instagram" />
            <Link href="https://www.instagram.com/zaemadethis" text="Isaiah Bullard - Instagram" />
            <Link href="https://www.zaemadethis.com" text="www.zaemadethis.com" />
          </div>
          <p>#partyatzaescrib 😏</p>
        </div>
      </div>
      <div className='content-half' style={{ overflow: 'auto', position: 'static' }}>
        <div className='new-media'>
          <img src="Bacc_Up_PAL.jpg" alt="The cover art for Bacc Up." style={{ width: '60%', minWidth: 150}} />
          <Link text={<h1>"Bacc Up"</h1>} href="https://audiomack.com/zaemadethis/song/bacc-up" />
          <h3>with We$ Clinton</h3>
          <h3 style={{ color: '#707070' }}><i>08/12/2022</i></h3>
          <p><i>We don't judge here. Twerk sum. Don't twerk sum. Shake it like She-Hulk and Meg. Anyone who doesn't appreciate consensual twerking should be shunned until they do.</i></p>
          <h2>Listen To "Bacc Up"!</h2>
          <iframe src="https://audiomack.com/embed/zaemadethis/song/bacc-up?background=1" scrolling="no" width="100%" height="252" frameBorder="0"></iframe>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', fontSize: 23 }}>
            <Link text='Spotify' href="https://open.spotify.com/track/5FlztytvuMCuYIlhD95Lj5" />
            <Link text='Apple Music' href="https://music.apple.com/us/album/bacc-up-single/1648928537" />
            <Link text='Bandcamp' href="https://isaiahbullard.bandcamp.com/track/bacc-up-with-we-clinton" />
            <Link text='SoundCloud' href="https://soundcloud.com/isaiah_bullard/bacc-up" />
            {/* <Link text='YouTube' href="https://www.youtube.com/watch?v=3DKOZ5QD73s" /> */}
          </div>
        </div>
        <div className='new-media'>
          <img src="Bleed_It_PAL.jpg" alt="The cover art for Bleed It." style={{ width: '60%', minWidth: 150}} />
          <Link text={<h1>"Bleed It"</h1>} href="https://audiomack.com/zaemadethis/song/bleed-it" />
          <h3>with Fuze</h3>
          <h3 style={{ color: '#707070' }}><i>08/12/2022</i></h3>
          <p><i>You ever seen a Maserati in the Wild West? Me neither, considering I was born a little too late to actually see the Wild West. But that's exactly what this song sounds like: a Maserati in the Wild West.</i></p>
          <h2>Listen To "Bleed It"!</h2>
          <iframe src="https://audiomack.com/embed/zaemadethis/song/bleed-it?background=1" scrolling="no" width="100%" height="252" frameBorder="0"></iframe>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', fontSize: 23 }}>
            <Link text='Spotify' href="https://open.spotify.com/track/0Q4w4W2N9kIpvD0VuB6YMt?si=840c38af31ae4e5c" />
            <Link text='Apple Music' href="https://music.apple.com/us/album/bleed-it/1640480650?i=1640480651" />
            <Link text='Bandcamp' href="https://isaiahbullard.bandcamp.com/track/bleed-it-with-fuze" />
            <Link text='SoundCloud' href="https://soundcloud.com/isaiah_bullard/bleed-it" />
            <Link text='YouTube' href="https://www.youtube.com/watch?v=3DKOZ5QD73s" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
